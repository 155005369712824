import { useStaticQuery, graphql } from "gatsby";

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        posts(
          where: { status: { equals: "published" } }
          orderBy: { publishDate: desc }
        ) {
          title
          id
          publishDate
          localization {
            code
          }

          content {
            document
          }
          contentFull {
            document
          }
          featureImage {
            extension
            filesize
            height
            id
            width
          }
        }
      }
    }
  `);
  const { posts } = data?.cms;
  return posts;
};

export default usePosts;
