import React from "react";
import { graphql } from "gatsby";
import Layout from "@/components/Layout";
import News from "@/components/Carts /NewsCart";
import "@/styles/global.css";
import usePosts from "@/hooks/usePosts";
import { CustomRenderer } from "@/utils/CustomRenderer";

const Home = (props: any) => {
  const posts = usePosts().slice(0, 3);
  const { menus } = props.pageContext;
  const { content, title } = props.data.cms.page;

  return (
    <Layout title={title} pageContext={menus}>
      <CustomRenderer document={content.document} />
      {/* News */}

      {/* {menus.page.includes("news") && (
        <div className="relative lg:container my-[2rem] lg:px-0 px-[20px]">
          <div className=" mx-auto lg:columns-3 md:columns-2">
            {posts.map((post: any) => (
              <News key={post.id} data={post} />
            ))}
          </div>
        </div>
      )} */}
    </Layout>
  );
};
export const query = graphql`
  query id($id: ID) {
    cms {
      page(where: { id: $id }) {
        title
        id
        content {
          document(hydrateRelationships: true)
        }
      }
    }
  }
`;
export default Home;
